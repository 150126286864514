<template>
    <div>
        <div v-if="!loadingAccounts">

            <page-header title="Users" >
                <div slot="right-content">
                    <app-btn @click="newUserDialogOpen = true" icon="mdi-plus">Add User</app-btn>
                </div>
            </page-header>
            <app-card hide-header>
                <app-data-table
                    :headers="headers"
                    :items="accounts"
                    @onRowClick="onRowClick($event)"
                    :loading="loadingAccounts"
                    loading-text="Loading Users..."
                >
                    <template #item.fullName="{item}">
                        {{item.fullName}}
                        <span class="ml-2">
                                <span class="ml-2">
                                    <app-chip v-if="item.isSafetyCoach" label color="green" small>Safety Coach</app-chip>
                                </span>
                            </span>
                    </template>

                    <template #item.accessibleOrganizations="{item}">
                      <div style="max-height: 100px; overflow: scroll">
                        <span v-for="(org, index) in item.accessibleOrganizations" class="ml-2" style="display: inline-block; padding-bottom: 4px; padding-top: 4px">
                            <app-chip v-if="isAdminOrgAccessType(org.organizationAccessTypeId)" label color="blue" small>{{org.name}}</app-chip>
                            <app-chip v-else label color="green" small>{{org.name}}</app-chip>
                        </span>
                      </div>
                    </template>

                    <!--	                    <template v-slot:expanded-item="{ headers, item }">-->
                    <!--		                    <td :colspan="4">-->
                    <!--			                    <div class="pa-4">{{ headers }}</div>-->
                    <!--		                    </td>-->
                    <!--		                    -->
                    <!--	                    </template>-->

                </app-data-table>
            </app-card>

        </div>
        <div v-else>
            <AppPageLoader text="Loading Users"/>

        </div>

        <dialog-form
            :open="newUserDialogOpen"
            @close="newUserDialogOpen = false"
            title="Add A New User"
            @submit="submitAddUserForm"
            :width="900"
            :height="900"
            :saving="savingNewUser"
        >

            <template v-slot:form-content>
                <add-user-form :new-user-form="newUserForm"></add-user-form>

            </template>
            <template v-slot:actions>

            </template>



        </dialog-form>
    </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import {createAccount, getAccounts, getUser, newAccountModel} from '@/services/userService'
import {getGroups} from "@/services/groups.service";
import {SET_SNACKBAR} from "@/store/mutation-types";
import AddUserForm from "@/components/UserPages/AddUserForm";
import EmptyState from "@/components/EmptyState";
import {isAdminOrgAccessType} from "@/js/shared/helper"

export default {
    name: "Users",
    components: {
        EmptyState,
        PageHeader,
        AddUserForm
    },
    data() {
        return {
            accounts: [],
            loadingAccounts: true,
            newUserDialogOpen: false,
            savingNewUser: false,
            newUserForm: {
                email: '',
                firstName: '',
                lastName: '',
                phoneNumber: '',
                jobTitle: '',
                isSafetyCoach: false,
                isBillingContact: false,
                professionalTypeId: null,
                organizationAccessTypeId: 1,
                groupIds: [],
                organizations: []
            },
            headers: [
                {
                    text: 'Name',
                    value: 'fullName',
                },
                // {
                //     text: 'Last Name',
                //     value: 'lastName',
                // },
                {
                    text: 'Email',
                    value: 'email',
                },
                {
                    text: 'Phone Number',
                    value: 'phoneNumber',
                },
                // {
                //   text: 'Email',
                //   value: 'email',
                // },
                // {
                //   text: 'Email',
                //   value: 'email',
                // },

            ],

        }
    },
    mounted() {
        if (this.$store.state.currentAccount.isEnterpriseAdminOrg){
            this.headers.push({
                text: 'Organizations',
                value: 'accessibleOrganizations',
            })
        }
        this.getAccounts()
    },
    methods: {
        onRowClick(item) {
            this.$router.push(`/users/${item.id}`)
        },
        async submitAddUserForm() {
            this.savingNewUser = true;
            let request = {...newAccountModel}
            const {
                firstName,
                lastName,
                email,
                phoneNumber,
                jobTitle,
                professionalTypeId,
                isSafetyCoach,
                isBillingContact,
                organizationAccessTypeId,
                groupIds,
                organizations
            } = this.newUserForm

            let organizationIds = organizations.map(function(o){
                return o.id;
            });
            request.firstName = firstName;
            request.lastName = lastName;
            request.email = email;
            request.phoneNumber = phoneNumber;
            request.jobTitle = jobTitle;
            request.professionalTypeId = professionalTypeId;
            request.isSafetyCoach = isSafetyCoach;
            request.isBillingContact = isBillingContact;
            request.organizationAccessTypeId = organizationAccessTypeId;
            request.groupIds = groupIds;
            request.organizationIds = organizationIds;

            let response = await createAccount(request);

            if (!response.isSuccess) {
                this.savingNewUser = false;
                return
            }

            this.$store.commit(SET_SNACKBAR, {
                text: 'User Successfully Created',
                color: 'success',
                open: true
            })
            await this.getAccounts()
            this.newUserDialogOpen = false;
            this.savingNewUser = false;

        },
        async getAccounts() {
            let response = await getAccounts()

            this.loadingAccounts = false

            if (!response.isSuccess) {
                return
            }

            response.accounts.forEach(account => {
                account.fullName = account.firstName + ' ' + account.lastName
            })

            this.accounts = response.accounts;
            // this.accounts = []
            this.loadingAccounts = false
        },
        isAdminOrgAccessType(accessTypeId) {
            return isAdminOrgAccessType(accessTypeId);
        }
    }
}
</script>

<style scoped>

</style>