<template>
    <div class="content-container">
        <div style="height: 60px;display: flex; align-items: center; justify-content: center; margin-bottom: 40px " class="">



            <img :src="logo" alt="logo" style="height: 50px" />




        </div>

        <div v-if="account.accountTypeId !== 4" style="display: flex; flex-direction: column; flex: 1">
            <div style="flex: 1" >
                <div class="app-sub-header pl-4 mb-4" style="color: #6ab6d0">
                    Main Menu
                </div>


                <router-link
                    :to="route.route"
                    v-for="route in routesTop"
                    :key="route.route"
                    class="sidebar-link"
                >

                    {{ route.label }}

                </router-link>
            </div>
            <div>
                <router-link
                    :to="route.route"
                    v-for="route in routesBottom"
                    :key="route.route"
                    class="sidebar-link"
                >

                    <!--                <div style="padding-right: 10px" >-->
                    <!--                    <v-icon class="icon">{{route.icon}}</v-icon>-->
                    <!--                </div>-->
                    {{ route.label }}
                </router-link>
<!--                <router-link -->
<!--                    to="https://help.swaymedical.com"-->
<!--                    class="sidebar-link">-->
<!--                    Help-->
<!--                </router-link>-->
                <a class="sidebar-link" target="_blank" href="https://help.swaymedical.com">
                    Help
                </a>
            </div>
        </div>
        <div v-else>
            <router-link
                v-for="route in covidUserRoutes"
                :to="route.route"
                :key="route.route"
                class="sidebar-link"
            >
                {{ route.label }}
            </router-link>
        </div>

    </div>
</template>

<script>
import swayLogo from "@/assets/images/sway-logo-white-v2.png";
import {
    mdiAccountGroup, mdiAccountOutline,
    mdiCardAccountDetailsOutline, mdiClipboardAccountOutline,
    mdiClipboardCheckMultipleOutline,
    mdiClipboardTextOutline, mdiCogOutline, mdiDeathlyHallows,
    mdiSpeedometer, mdiViewDashboard
} from "@mdi/js";
import {getProduct} from "@/js/utilities";
import readycheckLogo from "@/assets/images/readycheck-logo.svg";
import {isAdminOrgAccessType} from "@/js/shared/helper"
import {superAdminSymbol} from "@/js/shared/constants";

export default {
    name: "SidebarLeft",
    props: {
        company: {
            type: String
        },
    },
    data() {
        return {
            user: null,
            swayDashboardDialogOpen: false,
            logo: swayLogo,
            routes: [],
	        superAdminSymbol,
            // routes: [
            //     {
            //         label: 'Dashboard',
            //         route: '/dashboard',
            //         icon: mdiSpeedometer
            //     },
            //     {
            //         label: 'Profiles',
            //         route: '/profiles',
            //         icon: mdiCardAccountDetailsOutline
            //     },
            //
            //     {
            //         label: 'Groups',
            //         route: '/groups',
            //         icon: mdiAccountGroup
            //     },
            //     {
            //         label: 'Protocols',
            //         route: '/protocols',
            //         icon: mdiClipboardCheckMultipleOutline
            //     },
            //     {
            //         label: 'Codes',
            //         route: '/codes',
            //         icon: mdiClipboardTextOutline
            //     },
            //     {
            //         label: 'Tools',
            //         route: '/tools',
            //         icon: mdiClipboardAccountOutline
            //     }
            // ],
            covidUserRoutes: [
                {
                    label: 'Account',
                    route: '/account',
                    icon: mdiAccountOutline,
                }
            ]

        }
    },
    created() {
        let product = getProduct();
        this.logo = getProduct() === 'sway' ? swayLogo : readycheckLogo;
        // this.user = getUser()
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            
            console.log(this.$store.state.currentAccount.isEnterpriseAdminOrg)
            
            // const user = this.$store.state.currentAccount;
            if (isAdminOrgAccessType(this.$store.state.currentAccount.selectedOrganizationAccessTypeId) || this.$store.state.currentAccount.viewingAsSuper) {
                this.routes.push({
                        label: 'Users',
                        route: '/users',
                        icon: mdiClipboardAccountOutline
                    },
                        
                )
            }

            

        }
    },
    computed: {
        account() {
            return this.$store.state.currentAccount
        },
        isReadycheck() {
            let product = getProduct();
            return product === 'readycheck'
        },
        routesTop(){
            
            let items = [];
            
            if (this.$store.state.currentAccount.isEnterpriseAdminOrg){
                items = [
                    {
                        label: 'Dashboard',
                        route: '/dashboard',
                        icon: mdiSpeedometer
                    },
                    // {
                    //     label: 'Tools',
                    //     route: '/tools',
                    //     icon: mdiClipboardAccountOutline
                    // }
                ];
				
				
				
            } else{
                items = [
                    {
                        label: 'Dashboard',
                        route: '/dashboard',
                        icon: mdiSpeedometer
                    },
                    {
                        label: 'Profiles',
                        route: '/profiles',
                        icon: mdiCardAccountDetailsOutline
                    },

                    {
                        label: 'Groups',
                        route: '/groups',
                        icon: mdiAccountGroup
                    },
                    {
                        label: 'Protocols',
                        route: '/protocols',
                        icon: mdiClipboardCheckMultipleOutline
                    },
                    {
                        label: 'Codes',
                        route: '/codes',
                        icon: mdiClipboardTextOutline
                    },
                    {
                        label: 'Tools',
                        route: '/tools',
                        icon: mdiClipboardAccountOutline
                    }
                ];
            }

            if (isAdminOrgAccessType(this.$store.state.currentAccount.selectedOrganizationAccessTypeId) || this.$store.state.currentAccount.viewingAsSuper) {
                items.push({
                        label: 'Users',
                        route: '/users',
                        icon: mdiClipboardAccountOutline
                    },

                )
            }
			
			if (this.$store.state.currentAccount.viewingAsSuper) {
				items.push({
						label: `${superAdminSymbol} Reporting`,
						route: '/reporting',
						icon: mdiClipboardAccountOutline
					},
				
				)
			}
            
            return items;
        },
        routesBottom() {
            let items = [];

            if (!this.$store.state.currentAccount.viewingAsSuper) {
                items.push({
                    label: 'Account',
                    route: '/account',
                    icon: mdiAccountOutline,

                })
            }

            

            // 
            let selectedOrganizationAccessTypeId = !!this.$store.state.currentAccount ? this.$store.state.currentAccount.selectedOrganization.organizationAccessTypeId : this.account.selectedOrganization.organizationAccessTypeId
            // 
            if (isAdminOrgAccessType(selectedOrganizationAccessTypeId) || this.$store.state.currentAccount.isSuperAdmin) {
                items.push({
                    label: 'Organization',
                    route: '/organization',
                    icon: mdiCogOutline
                });
            }
            
            if (isAdminOrgAccessType(selectedOrganizationAccessTypeId) || this.$store.state.currentAccount.isSuperAdmin || this.$store.state.currentAccount.isBillingContact) {
                items.push({
                    label: 'Billing',
                    route: '/billing',
                    icon: mdiCogOutline
                });
            }
	
	        if (this.$store.state.currentAccount.isSuperAdmin) {
		        items.push({
				        label: `${superAdminSymbol} Org Management`,
				        route: '/org-management',
				        icon: mdiDeathlyHallows,
			        }
		        )
	        }

            // if (this.$store.state.currentAccount.isSuperAdmin || this.$store.state.currentAccount.viewingAsSuper) {
            //     items.push({
            //         label:  `${superAdminSymbol} Sway Reporting`,
            //         route: '/sway-reporting/organization-report',
            //         icon: mdiViewDashboard,
            //     })
            // }

            if (this.$store.state.currentAccount.isSuperAdmin || this.$store.state.currentAccount.viewingAsSuper) {
                items.push({
                    label: `${superAdminSymbol} Internal Sway Admin`,
                    route: '/super-admin',
                    icon: mdiDeathlyHallows
                })
            }
            return items
        }
    }
}
</script>

<style scoped>
.app-sub-header {
    text-transform: uppercase;
    color: var(--v-primary-base);
    font-weight: 600;
    font-size: .9em

}

.content-container {
    display: flex;
    flex: 1;
    height: 100%;
    background-color: #333333;
    flex-direction: column;
    /*padding-left: 10px;*/
    padding-top: 10px;
    padding-bottom: 10px;
    border-right: 1px solid lightgrey;
}

.sidebar-link {
    text-decoration: none;
    /*margin: 0px 0;*/
    display: flex;
    flex: 1;
    max-height: 30px;
    /*border-bottom-left-radius: 5px;*/
    /*border-top-left-radius: 5px;*/

    /*justify-content: center;*/
    align-items: center;
    padding: 20px;
	font-size: 1rem;
    /*color: var(--v-text-lighten1)*/
    color: #BDBDBD;
	
    /*color: #757575*/
    /*background-color: yellow;*/
}

a {
    /*color: #757575;*/
    text-decoration: none;
}

.router-link-active {
    /*color: #1C1C1C;*/
    /*!*background-color: var(--v-primary-lighten5);*!*/
    /*color: white;*/
    /*background-color: var(--v-primary-base);*/

    /*font-weight: bold;*/
    /*background-color: #ff0000;*/
    border-right: 5px solid var(--v-primary-base);
    /*border: 1px solid #EAEAEA;*/
    /*color: var(--v-text-darken1);*/
    color: white;
    font-weight: bold
    /*border-radius: 15px;*/
}

.router-link-active > div  {

    text-decoration: none;;
}

.router-link-active > div > .v-icon  {
    /*color: var(--v-primary-darken1)*/
    /*color: var(--v-secondary-darken2);*/
    color: var(--v-primary-base);
    /*color: white*/
}

.v-icon.v-icon {
    /*color: var(--v-text-lighten1);*/
    color: #BDBDBD
}

/*.logo-text {*/
/*  font-family: 'proxima-nova', 'Helvetica Neue', Helvetica, Arial, sans-serif;*/
/*}*/

</style>