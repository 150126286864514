import httpService from "@/services/httpService";
import {baseUrl} from "@/config"


const endpoint = baseUrl + "/Admin"


const getOrganizationsPaged = async (request) => {
    try {
        const response = await httpService.post(endpoint + '/GetOrganizationsPaged', request)
        return response
    } catch (e) {
    }
}

const getHistoricalDailyTestCounts = async (request) => {
    try {
        const response = await httpService.post(endpoint + '/GetHistoricalDailyTestCounts', request)
        return response
    } catch (e) {
    }
}

const getDailyNonCovidTestCountOnly = async (request) => {
    try {
        const response = await httpService.post(endpoint + '/GetDailyNonCovidTestCountOnly', request)
        return response
    } catch (e) {
    }
}

const getTotalTestCount = async (request) => {
    try {
        const response = await httpService.post(endpoint + '/GetTotalTestCount', request)
        return response
    } catch (e) {
    }
}
const getTodayTotalTestCount = async (request) => {
    try {
        const response = await httpService.post(endpoint + '/GetTodayTotalTestCount', request)
        return response
    } catch (e) {
    }
}
const getTotalProfileCount = async (request) => {
    try {
        const response = await httpService.post(endpoint + '/GetTotalProfileCount', request)
        return response
    } catch (e) {
    }
}

const getTotalOrgCount = async (request) => {
    try {
        const response = await httpService.post(endpoint + '/GetTotalOrgCount', request)
        return response
    } catch (e) {
    }
}

const getHubspotCompaniesWithoutNces = async (request) => {
    try {
        const response = await httpService.post(endpoint + '/GetHubspotCompaniesWithoutNces', request)
        return response
    } catch (e) {
    }
}
const updateHubspotCompanyModel = async (request) => {
    try {
        const response = await httpService.post(endpoint + '/UpdateHubspotCompanyModel', request)
        return response
    } catch (e) {
    }
}
const transferGroupOfProfiles = async (request) => {
    try {
        const response = await httpService.post(endpoint + '/TransferGroupOfProfiles', request)
        return response
    } catch (e) {
    }
}
const getAdminAccounts = async (orgId) => {
    try {
        return await httpService.post(endpoint + '/GetAdminAccounts?orgId=' + orgId )
    } catch (e) {

    }
}









export {
    getOrganizationsPaged,
    getHistoricalDailyTestCounts,
    getTotalTestCount,
    getDailyNonCovidTestCountOnly,
    getTodayTotalTestCount,
    getTotalProfileCount,
    getTotalOrgCount,
    getHubspotCompaniesWithoutNces,
    updateHubspotCompanyModel,
    transferGroupOfProfiles,
    getAdminAccounts
}