<template>

    <div id="create-invoice-page">

	    
        <div class="sway-form">
            <form style="display: flex; flex-direction: column">
                


                    <div id="org-info" style="padding-left:20px; float: left; line-height: 1.3em;">
                        <h3 style="line-height: 1.3em">
                            Organization: <strong>{{billingInfo.name}}</strong>
                        </h3>
                        <p>

                            <text v-if="hasSubscription">
                                Renewal Date: <strong>{{subscriptionExpiresOn}}</strong> <br />
                            </text>

                            <br />
                            Active Profiles: <strong>{{activeProfileCount}}</strong> <br />
                            Inactive Profiles: <strong>{{inactiveProfileCount}}</strong> <br />
                            <text v-if="unbilledMedicalProfileCount">Unbilled Medical Profiles: <strong>{{unbilledMedicalProfileCount}}</strong><br /></text>
                            Total Profiles: <strong>{{totalProfileCount}}</strong> <br />
                            Profiles not tested in 12 Months: <strong>{{yearInactiveProfileCount}}</strong> <br />
                            Profiles tested in last 12 Months: <strong>{{yearTestedProfileCount}}</strong> <br />
                            <!--Taxable Status: {{salesTaxStatus}}<br />-->
                        </p>

                        <text v-if="orgType === 'Athletic'">Subscribed Profiles: <strong>{{maxProfiles}}</strong> <br /></text>

                        <br /><br />

                        
                    </div>
	                
	                <app-card hide-header class="pa-4">
		                <v-container>
			                <div style="display: flex; align-items: center">
				                <img :src="isNewInvoice ? greenPlusSvg : blueEditSvg" alt="" class="mr-2" style="height: 24px">
				                <h2>{{isNewInvoice ? 'Create New Invoice' : `Edit Invoice #${currentInvoiceId}`}}</h2>
			                </div>
			                
			                <v-divider class="mt-4 mb-4"></v-divider>
			                <v-row >
				                <v-col :cols="3">
					                <h3>Invoice Details</h3>
				                </v-col>
				                <v-col :cols="9">
					                <v-row>
						                <v-col :cols="6">
							                <app-select
								                :items="invoiceTypes"
								                label="Invoice Type"
								                v-model="invoiceTypeId"
								                :disabled="!canEditAmounts"
							                ></app-select>
						                </v-col>
						                <v-col :cols="6">
							                <app-select
								                :items="approvalStatuses"
								                label="Approval Status"
								                v-model="approvalStatusId"
								                v-if="!isNewInvoice"
								                show-description
								                
							                ></app-select>
						                </v-col>
					                </v-row>
					                <v-row>
						                <v-col :cols="6" :lg="4">
							                <app-form-checkbox v-model="isPoInvoice" label="Is PO Invoice" :disabled="!canEditDescriptions" checkbox-left-side />
						                </v-col>
					                </v-row>
					                <v-row>
						                <v-col :cols="6" :lg="4">
							                <app-text-field v-model="poNumber" label="PO Number" v-if="isPoInvoice" :disabled="!canEditDescriptions" />
						                </v-col>
					                </v-row>
					                
					
					                
					               
				                </v-col>
			                </v-row>
			                <v-divider class="mt-4 mb-4"></v-divider>
			                <v-row >
				                <v-col :cols="3">
					                <h3>Invoice Dates</h3>
				                </v-col>
				                <v-col :cols="9">
					                <v-row>
						                <v-col :cols="6" >
							                <app-form-date-picker
								                v-model="dateOfSale"
								                label="Date Of Sale"
								                mask="##/##/####"
								                placeholder="MM/DD/YYYY"
								                :disabled="!canEditAmounts"
								                helper-message="Date of sale, for Accounting purposes (usually same as subscription renewal date)"
							                />
						                </v-col>
						                <v-col :cols="6">
							                <app-form-date-picker
								                v-model="amortizationEndDate"
								                label="Amortization End Date"
								                mask="##/##/####"
								                placeholder="MM/DD/YYYY"
								                :disabled="!canEditAmounts"
								                helper-message="Date of amortization end, for Accounting purposes (usually a year from due date)"
							                />
						                </v-col>
					                </v-row>
					                <v-row>
						                <v-col :cols="6">
							                <app-form-date-picker
								                v-model="invoiceDate"
								                label="Invoice Date"
								                mask="##/##/####"
								                placeholder="MM/DD/YYYY"
								                helper-message="Customer-facing date shown on invoice"
							                />
						                </v-col>
						                <v-col :cols="6">
							                <app-form-date-picker
								                v-model="displayDate"
								                label="Display Date"
								                mask="##/##/####"
								                placeholder="MM/DD/YYYY"
								                helper-message="Date when invoice is visible to customers"
							                />
						                </v-col>
					                </v-row>
					                <v-row>
						                <v-col :cols="6">
							                <app-form-date-picker
								                v-model="dueDate"
								                label="Due Date"
								                mask="##/##/####"
								                placeholder="MM/DD/YYYY"
								                helper-message="Date when invoice is due"
							                />
						                </v-col>
					                </v-row>
					                
                                    
					                
				                </v-col>
			                </v-row>
			                <v-divider class="mt-4 mb-4"></v-divider>
			                <v-row >
				                <v-col :cols="3">
					                <h3>Invoice Line Items</h3>
				                </v-col>
				                <v-col :cols="9">
					                <table id="invoice-line-items">
						                <tr>
							                <td style="width:300px"><h4>Description</h4></td>
							                <td style="width:300px"><h4>Detail (2nd line)</h4></td>
							                <td><h4>Category</h4></td>
							                <td style="width:100px"><h4>Quantity</h4></td>
							                <td style="width:100px"><h4>Unit Price </h4></td>
						                </tr>
						
						                <tr v-for="lineItem in currentLineItems">
							                <td>
								                <app-text-field v-model="lineItem.descriptionText" label="" :disabled="!canEditDescriptions" />
							                </td>
							                <td>
								                <app-text-field v-model="lineItem.detailText" label="" :disabled="!canEditDescriptions" />
							                </td>
							                <td>
								                <app-select
									                :items="invoiceItemTypes"
									                label=""
									                v-model="lineItem.descriptionCategory"
									                :disabled="!canEditDescriptions"
								                ></app-select>
							                </td>
							                <td>
								                <app-text-field v-model="lineItem.quantity" label="" rules="positive" :disabled="!canEditAmounts" />
							                </td>
							                <td>
								                <app-text-field v-model="lineItem.unitPrice" label="" rules="decimal" :disabled="!canEditAmounts" />
							                </td>
							                <td v-if="canEditAmounts">
								                <a href="#" @click="removeLineItem(lineItem)">Remove Line Item</a>
							                </td>
						                </tr>
					
					                </table>
					                <a v-if="canEditAmounts" href="#" @click="addLineItem">Add Line Item</a>
				                </v-col>
			                </v-row>
			                <v-divider class="mt-4 mb-4"></v-divider>
			                <v-row >
				                <v-col :cols="3">
					                <h3>Invoice Preview</h3>
				                </v-col>
				                <v-col :cols="9">
					                <div id="invoice-preview">
						                <InvoicePdf
							                :currentInvoice="invoiceInProgress"
							                :billingInfo="billingInfo"
							                :isFullpage="false"
						                ></InvoicePdf>
					                </div>
					                
				                </v-col>
			                </v-row>
			                <v-divider class="mt-4 mb-4"></v-divider>
			                <v-row>
				                <v-col :cols="3">
					                <h3>Invoice Options</h3>
				                </v-col>
				                <v-col :cols="9">
					                <div id="invoice-options" v-if="!isLoading">
						                <app-btn v-if="isNewInvoice" @click="createInvoice" style="margin-left: 10px">Create Invoice</app-btn>
						                <app-btn v-else-if="canEditDescriptions" @click="submitInvoiceEdits" style="margin-left: 10px">Confirm Changes</app-btn>
						
						                <app-btn v-if="!deletedOn" @click="openDeleteInvoiceDialog" style="margin-left: 10px">Delete Invoice</app-btn>
						
						                <app-btn @click="exportDraftInvoicePdf" style="margin-left: 10px">Download Draft Invoice</app-btn>
					                </div>
                                    <div v-else>
                                        <app-page-loader :text="'Loading'"></app-page-loader>
                                    </div>
				
				                </v-col>
				                
				                
			                </v-row>
		                </v-container>
		                
	                </app-card>
	

                <br />
                
            </form>
        </div>
        
        <dialog-form
                :open="isDeleteDialogOpen"
                @close="isDeleteDialogOpen = false"
                title="Confirm Delete Invoice"
                :width="500"
                @submit="deleteInvoice"
        >
            <template v-slot:form-content>
                <div class="inline-block-field">
                    <app-text-field v-model="voidReason" label="Void Reason" />
                </div>
            </template>
    
        </dialog-form>
    </div>

</template>

<script>
import ProfileTestPdfExportMixin from '@/mixins/profile-test-pdf-export'
import InvoicePdf from "@/components/invoice-pdf/InvoicePdf";
import moment from "moment";
import {updateInvoice,createInvoice,deleteInvoice} from "@/services/billing.service";
import greenPlusSvg from '@/assets/svgs/green-plus-circle.svg'
import blueEditSvg from '@/assets/svgs/blue-edit-icon.svg'
import {invoiceApprovalStatuses} from "@/js/shared/constants";
import {SET_SNACKBAR} from "@/store/mutation-types";

export default {
    name: "CreateInvoice",
    components: {InvoicePdf},
    data() {
        return {
            //minDate: moment().add(-1, "y"),
            //maxDate: moment().add(5,"y"),
            
            subscriptionExpiresOn: "",
            hasSubscription: false,
            activeProfileCount: 0,
            inactiveProfileCount: 0,
            unbilledMedicalProfileCount: 0,
            totalProfileCount: 0,
            yearInactiveProfileCount: 0,
            yearTestedProfileCount: 0,
            salesTaxStatus: "",
            orgType: "",
            maxProfiles: 0,
            sendInvoiceToOrg: false,
            isPoInvoice: false,
            
            approvalStatusId: 3,
            
            isDeleteDialogOpen: false,
            voidReason: "",
	
	        dateFormat: 'YYYY-MM-DD',
            invoiceDate: moment().format('YYYY-MM-DD'),
            dateOfSale: moment().format('YYYY-MM-DD'),
            displayDate: moment().format('YYYY-MM-DD'),
            dueDate: moment().add(30, 'd').format('YYYY-MM-DD'),
            amortizationEndDate: moment().add(1, 'y').format('YYYY-MM-DD'),
            poNumber: null,
            invoiceTypeId: null,
            deletedOn: null,
	        

            currentInvoiceId: null,
            
            currentLineItems: [{
                rowNum: 1,
                descriptionText: null,
                detailText: null,
                descriptionCategory: null,
                quantity: null,
                unitPrice: null,
            }],
            
            invoiceItemTypes: [
                { text: "Empty Line", value: 0 },
                { text: "Sports Annual Subscription", value: 1 },
                { text: "Clinical Annual Subscription", value: 14 },
                { text: "Clinical Monthly Subscription", value: 6 },
                { text: "Clinical Quarterly Subscription", value: 12 },
                { text: "Promotional Package", value: 10 },
                { text: "Sports Profiles", value: 3 },
                { text: "Sports Overage", value: 9 },
                { text: "Clinical Profiles (Pre-Paid)", value: 11 },
                { text: "Clinical Profiles (Post-Paid)", value: 13 },
                { text: "Discount", value: 7 },
                { text: "One Time Service Fee", value: 15 },
                { text: "Other", value: 5 },
                //{ text: "Users", value: "2" },
                //{ text: "Expense", value: "4" },
                //{ text: "Sales Tax", value: "8" },
                //{ text: "Covid-19 Profiles", value: "11" }
            ],
            invoiceTypes: [
                { text: "New Organization", value: 1 },
                { text: "Renewal", value: 2 },
                { text: "Overage", value: 3 },
                { text: "Upgrade", value: 4 },
                { text: "Other", value: 5 }
            ],
            approvalStatuses: [],
	
	        greenPlusSvg,
	        blueEditSvg,
            
            isLoading: false
        };
    },
    mixins: [ProfileTestPdfExportMixin],
    created() {

        if (!this.$store.state.currentAccount.isSuperAdmin){
            this.$router.push({ path: '/billing' });
            return;
        }
        
        if (!this.$route.query.id && this.billingInfo && this.billingInfo.billMethod === 4){
            this.isPoInvoice = true;
        }
        
        if (this.invoices.length < 1){
            return
        }

        if (this.$route.query.id){
            const {dateFormat} = this;
            this.currentInvoiceId = parseInt(this.$route.query.id);
            var currentInvoice = this.invoices.find(x => x.id === this.currentInvoiceId);

            this.invoiceDate = moment(currentInvoice.issuedOn).format(dateFormat);
            this.dateOfSale = moment(currentInvoice.startPeriodOn).format(dateFormat);
            this.displayDate = moment(currentInvoice.displayDate).format(dateFormat);
            this.dueDate = moment(currentInvoice.dueDate).format(dateFormat);
            this.amortizationEndDate = moment(currentInvoice.endPeriodOn).format(dateFormat);
            this.deletedOn = currentInvoice.deletedOn ? moment(currentInvoice.deletedOn).format(dateFormat) : null;
            this.poNumber = currentInvoice.poNumber;
            this.isPoInvoice = currentInvoice.isPOPending || currentInvoice.poNumber;
            this.invoiceTypeId = currentInvoice.invoiceTypeId;
            this.approvalStatusId = currentInvoice.approvalStatusId;
			
            
            var tempId = 1;
            this.currentLineItems = [];
            currentInvoice.invoiceItems.forEach(lineItem =>
            {
                this.currentLineItems.push({
                    id: lineItem.id,
                    invoiceId: lineItem.invoiceId,
                    tempId: tempId,
                    descriptionText: lineItem.name,
                    detailText: lineItem.description,
                    quantity: lineItem.quantity,
                    unitPrice: lineItem.total / lineItem.quantity,
                    descriptionCategory: lineItem.invoiceItemTypeId
                });
                tempId = tempId+1;
            })
        }
	
	    this.setApprovalStatusOptions()
    },
    props: {
        billingInfo: {
            required: true
        },
        invoices: {
            required: true
        }
    },
    methods: {
        async addLineItem() {
            const tempId = Math.max(...this.currentLineItems.map(x => x.tempId));
            this.currentLineItems.push({
                id: 0,
                invoiceId: this.currentInvoiceId,
                tempId: tempId+1,
                descriptionText: null,
                detailText: null,
                descriptionCategory: null,
                quantity: null,
                unitPrice: null,
            });
        },
        async removeLineItem(lineItem) {
            const index = this.currentLineItems.findIndex(x => x.rowNum === lineItem.rowNum);
            if (index > -1) {
                this.currentLineItems.splice(index, 1);
            }
        },
        async createInvoice(){
            this.isLoading = true;
            var res = await createInvoice(this.invoiceInProgress);
            if (res && res.isSuccess)
                await this.returnToInvoices();
            else
                this.$store.commit(SET_SNACKBAR, {
                    text: 'Error Creating Invoice',
                    color: 'error',
                    open: true
                });
                    
            this.isLoading = false;
        },
        async submitInvoiceEdits(){
            this.isLoading = true;
            var res = await updateInvoice(this.invoiceInProgress);
            if (res && res.isSuccess)
                await this.returnToInvoices();
            else
                this.$store.commit(SET_SNACKBAR, {
                    text: 'Error Updating Invoice',
                    color: 'error',
                    open: true
                });

            this.isLoading = false;
        },
        async openDeleteInvoiceDialog(){
            this.isDeleteDialogOpen = true;
        },
        async deleteInvoice(){
            this.isLoading = true;
            var res = await deleteInvoice(this.currentInvoiceId, this.voidReason);
            if (res && res.isSuccess)
                await this.returnToInvoices();
            else
                this.$store.commit(SET_SNACKBAR, {
                    text: 'Error Deleting Invoice',
                    color: 'error',
                    open: true
                });

            this.isLoading = false;
        },
        async returnToInvoices() {
            await this.$emit('getInvoices');
            await this.$router.push({ path: '/billing/view-invoices', query: { orgId: this.billingInfo.id, id: this.currentInvoiceId }});
        },
		setApprovalStatusOptions() {
			switch (this.approvalStatusId) {
				case 0:
					return this.approvalStatuses = invoiceApprovalStatuses; // include all statuses
				case 1:
				case 2:
					return this.approvalStatuses =  invoiceApprovalStatuses.filter(x => x.value === 1 || x.value === 2) // only return the automated options
				case 3:
					return this.approvalStatuses = []
			}
		}
    },
    computed: {
        isNewInvoice() {
            return !this.currentInvoiceId;
        },
        canEditDescriptions() {
            return this.isNewInvoice || (!this.currentInvoice.payment && !this.currentInvoice.deletedOn);
        },
        canEditAmounts() {
            return this.isNewInvoice || (!this.currentInvoice.payment && !this.currentInvoice.deletedOn && moment(this.invoiceDate) > moment() && moment(this.dateOfSale) > moment());
        },
        totalPrice() {
            var total = 0.0;
            this.currentLineItems.forEach(lineItem =>
            {
                if (lineItem.quantity && lineItem.unitPrice)
                {
                    total += lineItem.quantity * lineItem.unitPrice;
                }
            })
            
            return total.toFixed(2);
        },
        currentInvoice() {
            if (this.currentInvoiceId)
                return this.invoices.find(x => x.id === this.currentInvoiceId);
            
            return null;
        },
        invoiceInProgress() {

            var invoiceItems = [];
            this.currentLineItems.forEach(lineItem =>
            {
                if (lineItem.descriptionText && lineItem.quantity)
                {
                    invoiceItems.push({
                        "id": lineItem.id ?? 0,
                        "invoiceId": lineItem.invoiceId ?? 0,
                        "name": lineItem.descriptionText,
                        "description": lineItem.detailText,
                        "quantity": lineItem.quantity,
                        "subTotal": (lineItem.quantity * lineItem.unitPrice).toFixed(2),
                        "total": (lineItem.quantity * lineItem.unitPrice).toFixed(2),
                        "invoiceItemTypeId": lineItem.descriptionCategory,
                    });
                }
            })

            return {
                "invoiceItems": invoiceItems,
                "payment": null,
                "displayDateOfSale": this.dateOfSale,
                "displayInvoiceDate": this.invoiceDate,
                "displayDueDate": this.dueDate,
                "isRefunded": false,
                "isNotSent": true,
                "isOverdue": false,
                "id": this.currentInvoiceId ? this.currentInvoiceId : 0,
                "organizationId": this.billingInfo.id,
                "subTotal": this.totalPrice,
                "total": this.totalPrice,
                "issuedOn": this.invoiceDate,
                "startPeriodOn": this.dateOfSale,
                "endPeriodOn": this.amortizationEndDate,
                "isPOPending": !!(this.isPoInvoice && !this.poNumber),
                "poNumber": this.isPoInvoice && this.poNumber ? this.poNumber : null,
                "displayDate": this.displayDate,
                "dueDate": this.dueDate,
                "isSyncedToQB": false,
                "salesTaxRegion": null,
                "deletedOn": this.deletedOn ? this.deletedOn : null,
                "invoiceTypeId": this.invoiceTypeId,
                "approvalStatusId": this.approvalStatusId
            };
        },
	    
    }
}
</script>

<style scoped>

/* ------------- Create Invoice Page ------------- */
#create-invoice-page #po-number label {
    display: block;
    padding-top: 5px;
    padding-left: 9px;
}


</style>